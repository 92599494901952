/* eslint-disable react/no-danger */
import React from 'react'
import { string } from 'prop-types'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants } from '@/utils/transitions'

function TempScroller({ id }) {
	return (
		<AnimateEntrance
			variants={containerVariants}
			id={id}
			threshold={0.1}
			rootMargin="-100px 0px"
			className="relative"
		>
		<div className="px-8 py-4 md:hidden" style={{backgroundColor: '#bebebe'}}>
			<p className="pb-4">By moving the pressure points around, we create tactile effects in mid-air.</p>
			<p>We use the pressure points to create a wide range of tactile effects – from sculpting virtual lines and shapes to forming 3D controls in mid-air.</p>
		</div>
			<div className="relative w-full h-auto">
				<video
					preload="auto"
					loop
					autoPlay
					playsInline
					muted
					src="/haptics/HIW/Haptics_How_it_Works_1920x1080_edit.mp4"
					className="relative hidden lg:block object-contain w-full"
				/>
				<video
					preload="auto"
					loop
					autoPlay
					playsInline
					muted
					src="/haptics/HIW/Haptics_How_it_Works_MOBILE_1080x1080.mp4"
					className="relative block lg:hidden object-contain w-full"
				/>
			</div>
		</AnimateEntrance>
	)
}
TempScroller.propTypes = {
	id: string.isRequired
}

export default TempScroller
