/* eslint-disable react/no-danger */
import React from 'react'
import { string, number, func } from 'prop-types'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import SectionHeading from '@/components/global/SectionHeading'
import RichText from '@/utils/RichText'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'

export default function Section({
	id,
	title,
	subHeading,
	body,
	index,
	className,
	onAnimationComplete
}) {
	return (
		<AnimateEntrance
			variants={containerVariants}
			id={id}
			className={classNames(className)}
			rootMargin="-100px 0px"
			threshold={0.2}
			onAnimationComplete={onAnimationComplete}
		>
			<SectionHeading className="mb-12 text-xs text-white lg:hidden">
				{index}. {subHeading}
			</SectionHeading>
			<div className="w-3/4">
				<motion.h2
					variants={textVariants}
					className="mb-4 leading-tight text-secondary text-h2-fluid font-museoSansBold"
				>
					{title}
				</motion.h2>
				<motion.div variants={textVariants}>
					<RichText className="text-white" content={body} />
				</motion.div>
			</div>
		</AnimateEntrance>
	)
}

Section.propTypes = {
	title: string.isRequired,
	subHeading: string.isRequired,
	body: string.isRequired,
	index: number.isRequired,
	className: string,
	id: string.isRequired,
	onAnimationComplete: func.isRequired
}
