/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '@/components/Seo'
import Blocks from '@/utils/Blocks'
import HowItWorks from '@/templates/technology/HowItWorks'
import { cleanProps } from '@/utils'
import useConstant from '@/hooks/useConstant'
import * as T from '@/types'

function Haptics({ 
	pageContext: { media, wordpress_id },
	data: {
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(() => cleanProps(blocks, media))

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={data} media={media} currentPage={wordpress_id} />
			<HowItWorks />
		</>
	)
}

Haptics.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired
	})
}

export const query = graphql`
	query hapticsPageQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
	}
`

export default Haptics
