import React from 'react'
// import { number, func } from 'prop-types'
import { motion } from 'framer-motion'
import IconWrapper from '@/utils/IconWrapper'
import DownArrow from '@/icons/down-arrow.svg'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'

const AnimateIcon = motion.custom(IconWrapper)

export default function Hero() {
	const onClick = e => {
		e.preventDefault()

		const elm = document.getElementById('how-works-continue')

		if (elm) {
			const { top } = elm.getBoundingClientRect()

			window.scroll({
				top: top + window.pageYOffset - 100,
				behavior: 'smooth'
			})
		}
	}

	return (
		<AnimateEntrance
			variants={containerVariants}
			threshold={0.5}
			className="relative flex flex-col items-center justify-center md:py-24 lg:pb-0 md:min-h-page"
		>
			<img
				src="/haptics/HIW/hero-hand.png"
				alt=""
				className="absolute top-0 bottom-0 my-auto left-1/2 -x-25"
				loading="lazy"
			/>
			<div className="relative z-10 flex flex-col items-center justify-center w-full max-w-xl px-8 py-24 mx-auto xl:max-w-4xl">
				<motion.h2
					variants={textVariants}
					className="mb-4 leading-tight text-center text-white text-h1-fluid font-museoSansBold"
				>
					Turning ultrasound into virtual touch
				</motion.h2>
				<motion.p
					variants={textVariants}
					className="hidden mb-4 text-lg text-center text-white lg:block lg:mb-8"
				>
					Here’s how it works.
				</motion.p>
				<motion.div
					variants={textVariants}
					className="w-1 h-24 mb-4 bg-white lg:mb-8"
				/>
				<AnimateIcon
					as="a"
					onClick={onClick}
					href="#how-works-continue"
					variants={textVariants}
					wrapperClassName="text-white w-12"
					icon={DownArrow}
				/>
			</div>
		</AnimateEntrance>
	)
}

// Hero.propTypes = {
// 	setScreen: func.isRequired,
// 	index: number
// }
