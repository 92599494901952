/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'
// import { fade } from '@/utils/transitions'
import useMediaQuery from '@/hooks/useMediaQuery'
import Hero from '@/components/hiw/Hero'
import PageMenu from '@/components/hiw/PageMenu'
import PurpleRow from '@/components/hiw/PurpleRow'
import WhiteRow from '@/components/hiw/WhiteRow'
import TempScroller from '@/components/hiw/TempScroller'
import Section from '@/components/hiw/Section'
import ContactForm from '@/components/hiw/ContactForm'
import { HeroShouter } from '@/components/HeroShouter'
import { StickyNavContext } from '@/container/StickyNavContext'

export const variants = {
	initial: { opacity: 0, y: -250 },
	reveal: { opacity: 1, y: -250, transition: { duration: 0.1 } },
	place: { opacity: 1, y: 0, transition: { duration: 1.2 } }
}

// { pageContext: { blocks } }
function HowItWorks() {
	const [screen, setScreen] = useState()
	const { isStickyNav, setStickyNav } = useContext(StickyNavContext)
	const [animate, setAnimate] = useState('initial')
	const [hasAnimateDone, setAnimateDone] = useState()
	const isMobile = useMediaQuery('(max-width: 64em)')
	useEffect(() => {
		if(isMobile) {
			setAnimateDone(true)
			setAnimate('place')
		}
	}, [isMobile])
	useEffect(() => {
		if (screen === 6 && isStickyNav) {
			setStickyNav(false)

			return
		}

		if (screen !== 6 && !isStickyNav) {
			setStickyNav(true)
		}
	}, [isStickyNav, screen, setStickyNav])

	return (
		<div id="how-it-works">
			<div className="relative">
				<PageMenu className={classNames('hidden 2xl:block')} screen={screen} />

				<div className="relative w-full overflow-hidden bg-easing-b-how">
					<InView
						as="div"
						threshold={0.5}
						rootMargin="0px 0px"
						onChange={inView => inView && setScreen(0)}
					>
						<Hero setScreen={setScreen} index={0} />
					</InView>
					<div
						id="how-works-continue"
						className="relative lg:site-grid grid-dense"
					>
						<div className="absolute inset-y-0 right-0 flex items-center justify-end">
							<div className="relative w-1/2 mt-16 vsm:mt-32 sm:mt-64 lg:mt-0">
								<img src="/haptics/HIW/hiw-product.png" alt="" loading="lazy" />
								<motion.div
									className="absolute z-30 lg:block"
									style={{ width: '6.289%', top: '29.2%', left: '32.3%' }}
									variants={variants}
									animate={animate}
									initial="initial"
									onAnimationComplete={() => {
										if (animate === 'place') {
											setAnimateDone(true)
										}
									}}
								>
									<img
										className={classNames({
											block: !hasAnimateDone,
											hidden: hasAnimateDone
										})}
										src="/haptics/HIW/plug.png"
										alt="Haptics Sensor Plug"
										loading="lazy"
									/>
									<img
										src="/haptics/HIW/plug-end.png"
										className={classNames({
											block: hasAnimateDone,
											hidden: !hasAnimateDone
										})}
										alt="Haptics Senor Plug End"
										loading="lazy"
									/>
								</motion.div>
							</div>
						</div>

						<InView
							as="div"
							threshold={1}
							rootMargin="-50px 0px"
							onChange={inView => inView && setScreen(1)}
							className="relative z-10 max-w-xl px-8 mb-32 ml-auto md:px-12 lg:mb-16 lg:px-0 lg:column-6/5 2xl:mb-32 2xl:column-7/5 lg:max-w-full"
						>
							<Section
								id="ultrasound-speaker"
								onAnimationComplete={() => !isMobile && setAnimate('reveal')}
								index={1}
								subHeading="Ultrasound speaker"
								title="It all starts with one small ultrasound speaker."
								body="This small speaker emits ultrasound waves, which are too high a frequency for you to hear."
								className="g:mb-0"
							/>
						</InView>
						<InView
							as="div"
							threshold={1}
							rootMargin="-50px 0px"
							onChange={inView => inView && setScreen(2)}
							className="relative z-10 max-w-xl px-8 mb-32 mr-auto lg:mb-56 lg:mt-32 md:px-12 lg:px-0 lg:column-5/5 lg:max-w-full"
						>
							<Section
								id="array"
								index={2}
								onAnimationComplete={() => setAnimate('place')}
								subHeading="Array"
								title="We put lots of these speakers together to create an array."
								body="Every ultrasound speaker in the array can be individually controlled."
							/>
						</InView>
					</div>
				</div>
				<HeroShouter
					className="py-24 md:py-48 xl:py-64 text-secondary"
					key_word="endless <br />possibilities"
					key_copy="Multiple <br />ultrasound <br />speakers"
				/>
				<InView
					as="div"
					threshold={0.1}
					rootMargin="-100px 0px"
					onChange={inView => inView && setScreen(3)}
					className="relative mb-32 lg:mb-56"
				>
					<PurpleRow
						id="algorithms"
						setScreen={setScreen}
						index={3}
						subHeading="Algorithms"
						title="Each individual speaker is triggered at a different time."
						body="<p>Using our patented algorithms, the ultrasound speakers are triggered with very specific time differences. These time differences mean the ultrasound waves arrive at the same point in space, at the same time.</p>"
						image="/haptics/HIW/transducers.gif"
					/>
				</InView>
				<InView
					as="div"
					threshold={0.1}
					rootMargin="-100px 0px"
					onChange={inView => inView && setScreen(4)}
					className="relative mb-32 lg:mb-56"
				>
					<WhiteRow
						id="the-focal-point"
						setScreen={setScreen}
						index={4}
						subHeading="The focal point"
						title="The place where all the ultrasound waves coincide is called the focal point."
						body="<p class='mb-4'>Where the focal point is positioned in 3D space is programmable in real time. It can change position from instant to instant.</p><p>We use a <a href='/product/'>hand tracking device</a> to track the exact position of your hand and position the focal point at a spot on it.</p>"
						image="/haptics/HIW/Focal-point_v4.gif"
					/>
				</InView>
				<InView
					as="div"
					threshold={0.1}
					rootMargin="-100px 0px"
					onChange={inView => inView && setScreen(5)}
					className="relative"
				>
					<PurpleRow
						id="pressure-point"
						setScreen={setScreen}
						index={5}
						subHeading="Pressure point"
						title="From focal point to pressure point. Feel virtual touch come to life."
						body="<p>The combined ultrasound waves have enough force to create a tiny dent in your skin. We use this pressure point to create a vibration that touch sensors in your hands can detect. </p>"
						image="/haptics/HIW/pressure-point.jpg"
					/>
				</InView>
				<HeroShouter
					className="py-24 md:py-48 xl:py-64 text-secondary"
					key_word="virtual touch"
					key_copy="Now <br />it's wow <br />time"
				/>

				<InView
					as="div"
					threshold={0.1}
					rootMargin="-100px 0px"
					onChange={inView => inView && setScreen(6)}
					className="relative bg-easing-b-how"
				>
					<TempScroller id="virtual-touch" />
				</InView>
			</div>
			<InView
				as="div"
				threshold={0.1}
				rootMargin="-100px 0px"
				onChange={inView => inView && setScreen(7)}
			>
				<ContactForm />
			</InView>
		</div>
	)
}

export default HowItWorks
