import React, { memo } from 'react'
import { number, bool, string } from 'prop-types'
import classNames from 'classnames'
import useScrollTo from '@/hooks/useScrollTo'
import SectionHeading from '@/components/global/SectionHeading'
import { kebabCase } from '@/utils'

const PageMenu = memo(({ screen, className }) => {
	const scrollTo = useScrollTo()

	const onClick = (id, offset = 0) => {
		const section = document.getElementById(id)
		const { top } = section.getBoundingClientRect()
		scrollTo(0, top + window.pageYOffset - offset)
	}

	const pageLinks = [
		'Ultrasound speaker',
		'Array',
		'Algorithms',
		'The focal point',
		'Pressure point',
		'Virtual touch'
	]

	return (
		<div className={classNames('sticky z-40 h-1/2 top-2/5', className)}>
			<div className="w-full xl:site-grid">
				<div className="relative col-span-5">
					<ol
						className={classNames(
							'xl:px-4 transition-all z-40 absolute h-full w-full left-0 text-darkgrey',
							{
								'opacity-0':
									screen < 1 || screen > 6 || typeof screen === 'undefined'
							}
						)}
					>
						{pageLinks.map((page, index) => (
							<li key={page} className="mb-4">
								<SectionHeading
									className={classNames(
										'font-h4-fluid transition-opacity normal-case inline-flex font-museoSans tracking-normal text-darkgrey',
										{
											'opacity-100': screen === index + 1,
											'opacity-40': screen !== index + 1
										}
									)}
									animate={screen === index + 1}
									as="a"
									href={`#${kebabCase(page)}`}
									onClick={e => {
										e.preventDefault()
										onClick(kebabCase(page), window.innerHeight / 4)
									}}
									threshold={1}
								>
									{index + 1}. {page}
								</SectionHeading>
							</li>
						))}
					</ol>
				</div>
			</div>
		</div>
	)
})

PageMenu.propTypes = {
	screen: number,
	inView: bool,
	className: string
}

export default PageMenu
